import { Paper } from '@mui/material';
import React from 'react';
import logo from "../DesktopView/Images/expresspay_logo_white.svg";
import lottie from "lottie-web";
import snowfall from "../DesktopView/Images/Lottie/s-snow-fall.json";




const MainCard = ({ children, confettiStyles = {}, santaImgStyles = {}, confetti = snowfall, showSanta = true, showSnowFall = true, showTrees = true, showLogo = true, santaImg = null }) => {

	React.useEffect(() => {
		lottie.loadAnimation({
			container: document.querySelector(".header-snowfall"),
			animationData: confetti,
			repeat: false,
			rendererSettings: {
				preserveAspectRatio: 'none'
			}
		});
	}, []);


	return (
		<Paper elevation={8} className="main-card">
			<div className="main-card-heading" style={{ ...(showSanta && ({ marginBottom: '91.5px' }) )}}>
				{!!showSnowFall && (
					<div style={confettiStyles} className="header-snowfall"></div>
				)}
				{!!showLogo && (
					<img src={logo} alt="logo" className="site-logo" />
				)}
				{!!showSanta && (
					<img src={santaImg ?? '/santa.svg'} alt="santa" className="header-santa-img" style={santaImgStyles} />
				)}
				{!!showTrees && (
					<img src={'/trees.svg'} alt="trees" className="header-trees" />
				)}
			</div>
			<div className="main-card-body">
				{children}
			</div>
		</Paper>
	);
}


export default MainCard;

