import { useContext, useEffect } from "react";
import { UserContext } from "../user-context";
import { useHistory } from "react-router-dom";
import axios from 'axios';



const AuthProtection = ({ children }) => {
	const { state } = useContext(UserContext);
	const history = useHistory();

	const authCheck = async () => {
		try {
			const url = "https://secretsanta.cniinai.com/api.php";

			console.log('auth_code', state);

			const response = await axios.post(url, { request: 'auth_check', auth_code: state?.auth_code });
			const data = await response.data;

			if (data.status !== 1) {
				history.push('/loginform');
			}
		} catch (e) {
			history.push('/loginform');
			alert("An error occured");
		}
	};


	useEffect(() => {
		authCheck();
	}, []);



	return children;
}



export default AuthProtection;

