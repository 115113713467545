import Done from "../DesktopView/Done";
import { useHistory } from "react-router-dom";
import MainCard from "../components/main-card";

const SecondLoading = () => {
  const history = useHistory();

  setTimeout(() => {
    history.push("/displaypick");
  }, 2000);

  return (
    <MainCard showSanta={false}>
      <div className="loading-one">
        <Done />
        <h4 className="loading-msg">Match Found!</h4>
      </div>
    </MainCard>
  );
};

export default SecondLoading;
