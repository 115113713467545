import ava from "./Images/groupava.png";
import React, { useContext } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import FirstLoading from "./FirstLoading";
import { UserContext } from "../user-context";
import { LoadingButton } from "@mui/lab";
import AuthProtection from "./AuthProtection";
import MainCard from "../components/main-card";



const Welcome = () => {
  const history = useHistory();

  const { state, dispatch } = useContext(UserContext);

  const [loading, setLoading] = React.useState(false);

  const [checkingDraw, setCheckingDraw] = React.useState(true);
  const [error, setError] = React.useState("");

  const submitHandler = async () => {
    setLoading(true);
    try {
      const url = "https://secretsanta.cniinai.com/api.php";

      const response = await axios.post(url, { request: 'pick_draw', auth_code: state?.auth_code, season: new Date().getFullYear() });
      const data = await response.data;

      if (data.status == 1) {
        dispatch({ type: 'SAVE_DRAW', payload: data.data });
        history.push('/secondloading');
      } else {
        setError(data.message);
      }

    } catch (e) {
      setError("Failed to pick draw");
    } finally {
      setLoading(false);
    }

  }

  const drawCheck = async () => {
    setCheckingDraw(true);
    try {
      const url = "https://secretsanta.cniinai.com/api.php";

      const response = await axios.post(url, { request: 'draw_check', auth_code: state?.auth_code, season: new Date().getFullYear() });
      const data = await response.data;

      if (data.status == 1) {
        history.push('/displaypick');
      } else {
        setCheckingDraw(false);
      }
    } catch (e) {
      alert("An error occured");
    }
  }



  React.useEffect(() => {
    drawCheck();
  }, []);

  return (
    <AuthProtection>
      {checkingDraw ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '5vw' }}><CircularProgress /><p>Checking Your Status</p></div> : (
        <>
          {loading ? (<FirstLoading />) : (
            <MainCard>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <div style={{ marginBottom: '27px', padding: '0 20px' }}>
                  {error && (
                    <Alert severity="error">{error}</Alert>
                  )}
                </div>

                <p className="hello">👋 Hello {state?.alias ?? state?.full_name?.split(' ')[0]}</p>
                <h4 className="pac">Pick a colleague</h4>
                <p className="tap-text">
                  Tap on the button below to find out who you’re spoiling this
                  Christmas!
                </p>
                <div className="button-container">
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      height: "65px",
                      backgroundColor: "#174876",
                      "&:hover": {
                        backgroundColor: "#174876",
                        opacity: 0.9,
                        boxShadow: "0px 4px #10385D",
                        height: '65px',
                      },
                      borderRadius: "5px 5px 5px 5px",
                      fontWeight: "bold",
                      fontFamily: "Gotham",
                      fontSize: "16px",
                      boxShadow: "0px 4px #10385D",
                    }}
                    onClick={submitHandler}
                  >
                    Pick
                  </LoadingButton>
                </div>
              </div>
              <div className="ava">
                <img src={ava} alt="avatar" style={{ height: '80px' }} />
              </div>
            </MainCard>

          )}
        </>
      )}
    </AuthProtection>
  );
};

export default Welcome;
