import Snowfall from "../DesktopView/Snowfall";

const Snowfalls = () => {
  return (
    <div style={{ background: "red" }}>
      <Snowfall />
    </div>
  );
};

export default Snowfalls;
