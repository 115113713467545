import React, { createContext, useReducer, useEffect } from 'react';
const CryptoJS = require('crypto-js');

const secretKey = 'YourSecretKey'; // Replace with your secret key

// Encryption function
const encryptData = (data) => {
	const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
	return encryptedData;
};

// Decryption function
const decryptData = (encryptedData) => {
	const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
	const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
	return decryptedData;
};





const UserContext = createContext();

let initialState = {
	isLoggedIn: false
};

const userReducer = (state, action) => {

	switch (action.type) {
		case 'LOGIN':
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			};
		case 'REHYDRATE':
			return {
				...state,
				...action.payload,
			};
		case 'SAVE_DRAW':
			return {
				...state,
				draw: action.payload,
				isLoggedIn: true
			};
		case 'LOGOUT':
			return {
				...state,
				username: '',
				isLoggedIn: false
			};
		default:
			return state;
	}
};

const UserProvider = ({ children }) => {
	let storedState = localStorage.getItem('userState');
	
	if (storedState) {
		storedState = decryptData(storedState);
		initialState = storedState;
	}

	const [state, dispatch] = useReducer(userReducer, initialState);

	useEffect(() => {
		if (JSON.stringify(state) !== JSON.stringify(initialState)) {
			localStorage.setItem('userState', encryptData(state));
		}
	}, [state]);


	return (
		<UserContext.Provider value={{ state, dispatch }}>
			{children}
		</UserContext.Provider>
	);
};

export { UserContext, UserProvider };
