import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import axios from 'axios';
import { Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import MainCard from "../components/main-card";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
}));

const LoginForm = () => {
  const classes = useStyles();
  const history = useHistory();

  // TODO: rdr to welcome if already logged in

  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);


  const [emailError, setEmailError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState("Please enter your expressPay email");



  const submitHandler = async (e) => {
    e.preventDefault();
    setEmailError('');
    setSuccessMessage('');
    setLoading(true);
    try {
      const url = "https://secretsanta.cniinai.com/api.php";

      const response = await axios.post(url, { email, request: 'login' });
      const data = await response.data;

      if (data.status == 1) {
        setSuccessMessage(data.message);
      } else {
        setEmailError(data.message);
      }

    } catch (e) {
      setEmailError("Failed to login");
    } finally {
      setLoading(false);
    }

  }

  return (
    <MainCard>
      <div style={{ marginBottom: '27px', padding: '0 20px' }}>
        {successMessage && (
          <Alert severity="success">Please check your email for a one-time signin link</Alert>
        )}
        {emailError && (
          <Alert severity="error">{emailError}</Alert>
        )}
      </div>
      <h4 className="log-in">Get Started</h4>
      <form
        noValidate
        autoComplete="on"
        onSubmit={submitHandler}
        className="form"
        style={{ padding: "0 20px", margin: "0 auto", maxWidth: '402px' }}
      >
        <TextField
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          variant="outlined"
          label="Email"
          color="primary"
          fullWidth
          helperText={error}
          margin="dense"
          error={emailError}
          className={classes.field}
        ></TextField>
      </form>
      <div className="button-container">
        <LoadingButton
          loading={loading}
          variant="contained"
          size="large"
          onClick={submitHandler}
          fullWidth
          sx={{
            width: "402px",
            height: "65px",
            backgroundColor: "#174876",
            "&:hover": {
              backgroundColor: "#174876",
              opacity: 0.9,
            },
            borderRadius: "5px 5px 5px 5px",
            fontWeight: "bold",
            fontFamily: "Gotham",
            fontSize: "16px",
            //   marginTop: "20px",
            boxShadow: "0px 4px #10385D",
          }}
        >
          Send Login Link
        </LoadingButton>
      </div>
    </MainCard>
  );
};

export default LoginForm;