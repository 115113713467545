import React, { useState, useEffect } from 'react';

import Desktop from './DesktopView/Desktop';
import { UserProvider } from './user-context';
// import Mobile from './MobileView/Mobile';
// import useWindowSize from './utils/useWindowSize';

function SuperApp() {
  return (
    <UserProvider>
      <Desktop />
    </UserProvider>
  );
}

export default SuperApp;
