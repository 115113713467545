import React, { useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import axios from 'axios';
import { UserContext } from "../user-context";
import AuthProtection from "./AuthProtection";
import MainCard from "../components/main-card";
import confetti from "./Images/Lottie/confetti.json";
import christmasSong from "../christmas.mp3";

const DisplayPick = () => {
  const { state } = useContext(UserContext);

  const [checkingDraw, setCheckingDraw] = React.useState(!state?.draw);
  const [pick, setPick] = React.useState(state?.draw);


  const drawCheck = async () => {
    try {
      const url = "https://secretsanta.cniinai.com/api.php";

      const response = await axios.post(url, { request: 'draw_check', auth_code: state?.auth_code, season: new Date().getFullYear() });
      const data = await response.data;

      if (data.status == 1) {
        setPick(response.data.data);
        setCheckingDraw(false);
      }
    } catch (e) {
      alert("An error occured");
    }
  }



  useEffect(() => {
    drawCheck();
  }, []);


  return (
    <AuthProtection>
      {checkingDraw ? null : (
        <MainCard confettiStyles={{ height: 'min(850px, 90vh)', zIndex: 4 }} confetti={confetti} santaImg={pick?.photo ?? `https://api.dicebear.com/7.x/micah/svg?seed=${pick?.full_name}`} santaImgStyles={{ border: 'solid 6px #eef4ff' }}>
          <audio loop src={christmasSong} autoPlay style={{ opacity: 0, position: 'absolute', zIndex: 0, top: '-1000vh'}}></audio>
          <div className="desk-pick">
            {pick?.alias ?? pick?.full_name}
          </div>
          <div className="desk-dept">
            {pick?.department}
          </div>
          <div style={{ padding: '0 22px', marginBottom: '50px', textAlign: 'center' }}>
            <hr style={{ borderBottom: 'solid 1px rgb(234, 234, 234)', borderTop: 'none', maxWidth: '200px' }} />
          </div>
          <div className="what-next">What's Next?</div>
          <div className="instructions">
            Don't disclose your pick to anyone. Submit your gift to HR before
            20th of December and keep your fingers crossed for an amazing gift
            from someone else
          </div>
          <Button
            href='/'
            variant="contained"
            size="large"
            fullWidth
            sx={{
              height: "65px",
              backgroundColor: "#174876",
              "&:hover": {
                backgroundColor: "#174876",
                opacity: 0.9,
                boxShadow: "0px 4px #10385D"
              },
              borderRadius: "5px 5px 5px 5px",
              fontWeight: "bold",
              fontFamily: "Gotham",
              fontSize: "16px",
              boxShadow: "0px 4px #10385D",
            }}
          >
            Done!
          </Button>
        </MainCard>
      )}
    </AuthProtection>
  );
};

export default DisplayPick;
