import LoadingOne from "../DesktopView/LoadingOne";
import MainCard from "../components/main-card";

const FirstLoading = () => {
  return (
    <MainCard showSanta={false}>
      <div className="loading-one">
        <LoadingOne />
        <h4 className="loading-msg">Matching you with someone...</h4>
      </div>
    </MainCard>
  );
};

export default FirstLoading;
